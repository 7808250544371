// external modules
import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { OutboundLink, trackCustomEvent } from "gatsby-plugin-google-analytics"

// internal modules
import catalog from "../../../../downloads/ctrg_low_0722.pdf"

// constants
import { containerWidth } from "../../../constants/css.json"
import { langTable } from "../../../constants/language"

function Summary({
  langIndex = 0,
  setLangIndex,
}: {
  langIndex: number
  setLangIndex: (index: number) => void
}) {
  const downloadHanlder = () => {
    trackCustomEvent({
      // string - required - The object that was interacted with (e.g.video)
      category: "Catalog Download Button",
      // string - required - Type of interaction (e.g. 'play')
      action: "Click",
      // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
      label: "PLANZ FIRST LANDING PAGE",
    })
  }
  return (
    <div css={container}>
      <div css={top}>
        <div>
          <h1 css={top__title}>{langTable[langIndex].footer__title}</h1>
          <div css={top__companyName}>
            © {new Date().getFullYear()},{` `}
            {langTable[langIndex].footer__companyNmae}
          </div>
        </div>
      </div>
      <div css={mid}>
        {langTable[langIndex].footer__summary1}
        <br />
        {langTable[langIndex].footer__summary2}
        <br />
        {langTable[langIndex].footer__summary3}
      </div>

      <div css={language} style={{ marginTop: "12px" }}>
        <h1 style={{ marginBottom: "8px" }}>언어선택</h1>
        <button style={{ marginRight: "8px" }} onClick={() => setLangIndex(0)}>
          Korean
        </button>
        <button onClick={() => setLangIndex(1)}>Japanese</button>
      </div>
      <div css={bottom}>
        <OutboundLink
          css={bottom__link}
          href="https://brunch.co.kr/@rladbtls003#articles"
        >
          {langTable[langIndex].footer__brunch}
        </OutboundLink>
        <OutboundLink
          css={bottom__link}
          href="https://www.instagram.com/planz_coffee/"
        >
          {langTable[langIndex].footer__instagram}
        </OutboundLink>
        <OutboundLink
          css={bottom__link}
          href="https://www.facebook.com/PLANZCOFFEE/"
        >
          {langTable[langIndex].footer__facebook}
        </OutboundLink>
      </div>
    </div>
  )
}

const language = css`
  @media (max-width: ${containerWidth}) {
    margin-left: 16px;
  }
`

const container = css`
  @media (max-width: ${containerWidth}) {
    padding-bottom: 0px;
    width: 100%;
  }
  margin-top: 46px;
  width: ${containerWidth};
  color: rgb(116, 116, 116);
`
const top = css`
  @media (max-width: ${containerWidth}) {
    padding-left: 20px;
  }
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`
const top__links = css`
  @media (max-width: ${containerWidth}) {
    display: none;
  }
  margin-left: 50px;
  text-decoration: none;
  font-weight: bold;
  color: rgb(33, 33, 33);
  font-family: "Open Sans Reg";
`

const mid = css`
  @media (max-width: ${containerWidth}) {
    padding-left: 20px;
    line-height: 25px;
  }
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  line-height: 30px;
`

const bottom = css`
  @media (max-width: ${containerWidth}) {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  margin-top: 108px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  /* justify-content: space-between; */
`
const bottom__link = css`
  @media (max-width: ${containerWidth}) {
    display: none;
  }
  text-decoration: none;
  display: flex;
  flex-direction: row;
  color: rgb(33, 33, 33);
  margin-right: 45px;
`

const top__title = css`
  font-size: 20px;
`
const top__companyName = css`
  margin-top: 16px;
  color: rgb(181, 181, 181);
`

export default Summary
