// external modules
import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { langTable } from "../../../constants/language"

// internal modules

type TermsProps = {
  langIndex: number
}
// 이용약관과 개인정보 처리방침 링크
const Terms: React.FC<TermsProps> = ({ langIndex = 0 }) => (
  <div css={terms}>
    <Link css={termsLink} to="/terms/TS20200727/">
      {langTable[langIndex].footer__term1}
    </Link>
    <Link css={privacyLink} to="/privacy/PP20200727/">
      {langTable[langIndex].footer__term2}
    </Link>
  </div>
)

const terms = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: Spoqa Han Sans;
  margin-top: 10px;
`

const termsLink = css`
  text-decoration: none;
  color: rgb(116, 116, 116);
  margin-right: 20px;
`
const privacyLink = css`
  text-decoration: none;
  color: rgb(116, 116, 116);
`

export default Terms
