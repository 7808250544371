import { a } from "react-spring"
export const KOR = 0
export const JPN = 1

export const langTable = [
  {
    // 1. Introduction
    introduction__sub__title: "새로 만들 사내카페가 고민이시라구요?",
    introduction__title__upper: "카페보다 매력적인",
    introduction__title__lower: "플랜즈 커피스테이션 어떠세요?",
    introduction__welcome__title: " ₩0원 : 설치 및 운영비용 무료!?",
    introduction__button: "5초만에 상담 신청하기",
    // 2.reason
    reason__title__upper: "대기업, 관공서등 여러 회사들이",
    reason__title__lower: "플랜즈를 선택한 이유 세가지",
    reason__subTitle__upper:
      "사내 휴게공간에서 최상의 카페 서비스를 제공합니다 👍",
    reason__subTitle__lower: "",
    reason__cards: [
      {
        title: "바리스타의 커피를 사무실에서",
        subTitle: "바리스타가 만든 커피를\n사무실에서 마실 수 있습니다",
      },
      {
        title: "라떼부터 티까지 다양한메뉴",
        subTitle: "신선한 우유로 만든 라떼 부터\n과일음료 까지 다양한 메뉴",
      },
      {
        title: "본사 직영으로 도입비 0원!",
        subTitle:
          "본사에서 직영으로 관리 운영하기에\n설치비, 관리비가 모두 무료 입니다",
      },
    ],
    // 4.case
    case__list: [
      {
        title: "야간 근무자들도 커피를\n즐길 수 있게 되었어요",
        subTitle:
          "사내에 카페가 있어서 낮에는 커피를 즐길 수 있었지만 밤에 근무하시는 분들은 커피를 마실 수 없었습니다",
        name: "반도체 D기업\n(평택)",
        comment:
          "낮에도 카페 대기 줄이 무인카페와 분산되면서, 대기 시간이 많이 줄었습니다",
      },
      {
        title: "그냥 지나치던 공간이\n임직원들의 쉼터가 되었어요",
        subTitle:
          "평소에 잘 이용하지 않던 사내 휴게공간이었는데 플랜즈커피 도입 후, 앚을 자리가 없을 정도로 붐비게 되었습니다",
        name: "대형그룹 D사\n(성남)",
        comment:
          "많은 임직원들이 좋아해주면서 회사 내에서도 성과를 인정받아 좋았습니다",
      },
      {
        title: "커피부터, 라떼, 음료류 까지\n 다양한 메뉴구성이 좋았어요",
        subTitle:
          "아메리카노 부터 각종 라떼, 과일음료까지\n 다양한 메뉴가 준비되어 있습니다",
        name: "패스트파이브\n(서울)",
        comment:
          "진짜 카페 못지않은\n 다양한 메뉴가 있고\n 맛도 카페만큼 좋았어요",
      },
      {
        title: "생산직군의 휴식의 질과\n업무 능률이 좋아졌어요",
        subTitle:
          "사무직들은 외부에서 커피를 사와서 마시기도 하지만 생상직들은 업무시간에 커피를 마실 수 없기 때문에 사실상 커피를 즐길 수 없었습니다",
        name: "현차 협력 I사\n(수원)",
        comment:
          "생상직 분들의 휴식의 만족도가 높아져 업무 능률도 많이 향상되었어요",
      },
    ],

    // 5.pros
    pros__title__upper: "직접 만든 기계와 커피",
    pros__title__lower: " 플랜즈는 확실하게 합니다",
    pros__p1: "전지점 담당 매니저를 통해 직접! 직영관리",
    pros__p2: "AS 엔지니어, 냉장물류 당일출동",
    pros__p3: "1대1 맞춤형 메뉴및 철저한 위생 관리",
    pros__p4: "고객 원격대응 24시간 대기",

    // 6. needs(interview
    needs__itv1: {
      name: "강** 심사역 (공공기관)",
      cmt1:
        "플랜즈커피를 통해서 새로만든 라운지 공간을 카페로 탈바꿈 시켜 모두가 좋아하는 장소로 만들 수 있었어요!",
      // cmt2: "새로만든 라운지 공간을",
      // cmt3: "카페로 탈바꿈 시켜",
      // cmt4: "모두가 좋아하는 장소로",
      // cmt5: "만들 수 있었어요!",
    },
    needs__itv2: {
      name: "소** 과장 (대형 D그룹)",
      cmt1:
        "회사 복지 포인트로 결제할 수 있어서 맛있는 커피를 맘편히 이용할 수 있다는게 가장 좋은것 같아요!",
      // cmt2: "",
      // cmt3: "",
      // cmt4: "",
      // cmt5: "",
    },
    needs__itv3: {
      name: "안** 매니저 (국내 1위 공유 오피스)",
      cmt1:
        "회사에서 운영하는 각 거점 오피스 별 인원 구성 현황이나 상황에 맞춰 UI, 할인율을 조정해 주셔서 고민거리가 많이 줄었습니다!",
      // cmt2: "현황이나",
      // cmt3: "상황에 맞춰 UI, 할인율을 조정",
      // cmt4: "해 주셔서",
      // cmt5: "고민거리가 많이 줄었습니다!",
    },

    // 7. try
    try__title: "맛보고 결정하세요!",
    try__subTitle:
      "본사 직원이 직접 방문하여 무료 시음 서비스를 제공합니다.\n플랜즈 커피의 다양한 음료를 무료로 시음해보세요 😊",
    try__button: "무료 시음회 신청하기",

    // 8. news
    news__title__category: "보도자료",
    news__title__upper: '무인 사내카페 "플랜즈커피"',
    news__title__lower: "분당두산타워 입점",
    news__content__upper: "플랜즈커피가 분당에 위치한 두산그룹 신사옥",
    news__content__lower: "분당두산타워에 입점했다고 8일 밝혔다.",
    news__link: "자세히보기",

    // 9. interview and hotissue
    interview__title__upper: "미래형 카페의",
    interview__title__lower: "상징적인 회사가 될것",
    interview__content: "퓨처플레이 류중희 대표 - EO",

    hotIssue__title__upper: "관공서도 모던하고",
    hotIssue__title__lower: "따뜻할 수 있습니다",
    hotIssue__content: "한국벤처투자의 새로운 라운지카페",

    // 10. cleaning
    cleaning__title: "위생",
    cleaning__content__upper:
      "안전하고 신선한 음료제공을 위해서 플랜즈는 완벽의 완벽을 기합니다.",
    cleaning__content__lower:
      "모든 지점에서 위생을 완결하게 하기 위해 과학적이고, 헌신적인 노력을 기울이고 있습니다.",
    cleaning__link: "플랜즈의 위생에 대해 더 알아보기",

    // 11. menu
    menu__title__upper: "즐거움 가득한",
    menu__title__lower: "티타임을 원한다면",
    menu__content:
      "스페셜티 커피, 라떼, 과일 음료 등 각양각색의 다양한 메뉴들이 준비되어 누구나 맘에드는 음료를 마실수 있어요",

    // 12. footer
    footer__title: "플랜즈커피",
    footer__companyNmae: "Planz coffee Inc.",
    footer__summary1: "대표 최준혁 / 사업자 등록번호 523- 86 -00977",
    footer__summary2: "경기도 안양시 만안구 전파로24번길 62 삼부스퀘어 3층",
    footer__summary3: "대표전화 02 6956 0232 팩스 02 6956 0233",
    footer__brunch: " 플랜즈 공식 브런치",
    footer__instagram: "플랜즈 인스타그램",
    footer__facebook: "플랜즈 페이스북",
    footer__term1: "이용약관",
    footer__term2: "개인정보처리방침",

    // 13. contact modal
    contact__modal__title: "플랜즈커피 문의하기",
    contact__modal__subTitle:
      "간단히 작성해주시면 최대한 빠르게 답변 드리겠습니다",
    contact__modal__company__label: "기업명 또는 담당자 성함",
    contact__modal__company__placeholder:
      "기업명 또는 담당자 성함을 입력해주세요",
    contact__modal__phone__label: "휴대전화번호",
    contact__modal__phone__placeholder: "휴대전화번호를 입력해주세요",
    contact__modal__email__label: "이메일",
    contact__modal__email__placeholder: "이메일 주소를 입력해주세요",
    contact__modal__cancel: "취소",
    contact__modal__request: "문의하기",
  },
  {
    introduction__sub__title: "新しく作る社内カフェが悩みですって？",
    introduction__title__upper: "カフェより魅力的な",
    introduction__title__lower: "プランズコーヒーステーションはどうですか？",
    introduction__welcome__title: " ¥0 : 設置及び運営費用無料!?",
    introduction__button: "5秒で相談申し込み",
    // 2.reason
    reason__title__upper: "大企業、官公庁など様々な会社が",
    reason__title__lower: "プランズコーヒーを選択した理由の3つの",
    reason__subTitle__upper: "無人コーヒーステーションで社内の休憩スペースで",
    reason__subTitle__lower: "最上のカフェサービスをご提供します。",
    reason__cards: [
      {
        title: "バリスタのコーヒーをオフィスで",
        subTitle: "バリスタが作ったコーヒーを\nオフィスで飲むことができます。",
      },
      {
        title: "ラテからティーまで様々なメニュー",
        subTitle:
          "新鮮な牛乳で作ったラテから\nフルーツドリンクまで豊富なメニュー",
      },
      {
        title: "本社直営で導入費 0¥",
        subTitle:
          "本社が直営で管理運営するのに\n設置費、管理費が全て無料です。",
      },
    ],
    // 4.case
    case__list: [
      {
        title: "空間企画からデザインまで\n すべてのプロセスを助けてください。",
        subTitle:
          "事務環境と構成員に合わせた用途及びデザインに合わせて\n スペースを新たにデザインすることができます。",
        name: "韓国ベンチャー投資",
        comment:
          "“くすんだ社内カフェを\n ミーティングルームと\n ラウンジカフェに\n 実用的で感性的に\n 変身させてくれました。”",
      },
      {
        title:
          "コーヒーから、ラテ、飲み物まで\n多様なメニュー構成が良かったです。",
        subTitle:
          "アメリカーノから各種ラテ、フルーツドリンクまで\n様々なメニューをご用意しております。",
        name: "FASTFIVE ソウル駅",
        comment:
          "“本物のカフェに劣らない\n様々なメニューがあり、\n味もカフェほど\n良かったです。”",
      },
      {
        title: "注文だけしてください。\n完成した飲み物を差し上げます！",
        subTitle:
          "カフェのように注文だけしてください。\n完成した1杯を差し上げます, 平均27秒かかります。",
        name: "FST グループ",
        comment:
          "“注文だけして待つと\n完成した飲み物が\n提供されるのが\n不思議で便利でした”",
      },
      {
        title: "1階まで行かなくても\nカフェがあっていいですよ",
        subTitle:
          "カフェを導入するには曖昧な休憩室がありますか。\n機器設置だけでミニカフェを作ることができます。",
        name: "斗山新社屋ラウンジ",
        comment:
          "“1階のカフェまで\n行ってくるのに\n30分かかりますが\nラウンジで飲めて\n大好きです。",
      },
    ],
    pros__title__upper: "自家製の機械とコーヒー",
    pros__title__lower: "プランズは確実にします。",
    pros__p1: "全支店担当マネージャーを通じて直接 直営管理",
    pros__p2: "「ASエンジニア、冷蔵物流当日出動」",
    pros__p3: "1対1オーダーメイドメニュー及び徹底した衛生管理",
    pros__p4: "顧客遠隔対応24時間待機",

    // 6. needs(interview
    needs__itv1: {
      name: "***審査駅（公共機関）",
      cmt1: "プランズコーヒーを通じて新し",
      cmt2: "く作ったラウンジスペースをカ",
      cmt3: "フェに変身させて",
      cmt4: "みんなの好き",
      cmt5: "な場所へ作ることができました。",
    },
    needs__itv2: {
      name: "***課長(大型Dグループ)",
      cmt1: "会社",
      cmt2: "の福祉ポイント",
      cmt3: "で決済が可",
      cmt4: "能で美味しいコーヒーを気軽に利用で",
      cmt5: "きるということが一番いいと思います。",
    },
    needs__itv3: {
      name: "*** マネージャー(韓国1位コンユオフィス)",
      cmt1: "会社運営の各拠点オフィス別人数構成",
      cmt2: "現況や状況",
      cmt3: "に合わせてUI割引率などを ",
      cmt4: "調整してくださって悩み",
      cmt5: "がかなり減りました。",
    },

    // 7. try
    try__title: "無料で試飲してみてください。",
    try__subTitle: "フレンズが提供する飲み物を無料で試飲することができます。",
    try__button: "5秒で相談申し込み",

    // 8. news
    news__title__category: "報道資料",
    news__title__upper: "無人社内カフェ「フレンズコーヒー」",
    news__title__lower: "盆唐斗山タワー入店",
    news__content__upper:
      "プランズコーヒーが盆唐に位置する斗山グループの新社屋",
    news__content__lower:
      "盆唐（ブンダン）~斗山（トゥサン）タワーに出店したと8日、明らかにした。",
    news__link: "詳細を見る",

    // 9. interview and hotissue
    interview__title__upper: "未来型カフェの",
    interview__title__lower: "象徴的な会社になるだろう。",
    interview__content: "CEO Futureplay -EO",

    hotIssue__title__upper: "官公庁もモダンで",
    hotIssue__title__lower: "暖かくなることができます。",
    hotIssue__content: "韓国ベンチャー投資の新しいラウンジカフェ",

    // 10. cleaning
    cleaning__title: "Sanitization",
    cleaning__content__upper:
      "安全で新鮮な飲み物を提供するために、フレンズは完璧な完璧を期します。",
    cleaning__content__lower:
      "あらゆる地点で衛生を完結させるために科学的で献身的な努力を傾けています。",
    cleaning__link: "プランズの衛生関連リンク",

    // 11. menu
    menu__title__upper: "楽しさいっぱいの",
    menu__title__lower: "ティータイムを望むなら",
    menu__content:
      "スペシャルティ、コーヒー、ラテ、フルーツドリンク、お茶など様々なメニューが用意され、誰でもお気に入りの飲み物が食べられます。",

    // 12. footer
    footer__title: "無人コーヒーステーションプランズコーヒー",
    footer__companyNmae: "Planz coffee Inc.",
    footer__summary1: "523-86-00977",
    footer__summary2: "168, Yeoksam-ro, Gangnam-gu, Seoul, Republic of Korea",
    footer__summary3: "phone 02 6956 0232 fax 02 6956 0233",
    footer__brunch: "Planz brunch",
    footer__instagram: "Planz instagram",
    footer__facebook: "Planz facebook",
    footer__term1: "利用規約",
    footer__term2: "個人情報処理方針",

    // 13. contact modal
    contact__modal__title: "プランズコーヒーに関するお問い合わせ",
    contact__modal__subTitle:
      "簡単に作成していただければ、できるだけ早くお答えします。",
    contact__modal__company__label: "企業名または担当者のお名前",
    contact__modal__company__placeholder:
      "企業名または担当者のお名前を入力してください。",
    contact__modal__phone__label: "携帯電話番号",
    contact__modal__phone__placeholder: "携帯電話番号を入力してください。",
    contact__modal__email__label: "Email",
    contact__modal__email__placeholder: "Emailアドレスを入力してください。",
    contact__modal__cancel: "キャンセル",
    contact__modal__request: "問い合わせ",
  },
]
