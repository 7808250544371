import React from "react"
import { css } from "@emotion/core"
import { Link } from "gatsby"
import { string } from "prop-types"

import { setMq } from "../../../utils/setMediaQuerie"

type DividerProps = {
  space?: spaceType
}

type spaceType = string

function Divider({ space }: DividerProps) {
  return <div css={line(space)} />
}

const line = (space?: spaceType) => css`
  background-color: rgb(227, 227, 227);
  height: 1px;
  width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  margin: ${space ? `${space} 0px` : "40px 0px"};
  ${setMq[0]} {
    width: 100%;
  }
`

export default Divider
