// external modules
import React from "react"
import { css } from "@emotion/core"

// internal modules
import partners from "../../../images/partners.png"

// constants
import { containerWidth } from "../../../constants/css.json"

const Partners: React.FC = () => (
  <div css={container}>
    <img css={partnerLogo} src={partners} />
  </div>
)

const container = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${containerWidth};
`

const partnerLogo = css`
  @media (max-width: ${containerWidth}) {
    display: none;
  }
  height: 80px;
  margin-right: 10px;
`

export default Partners
