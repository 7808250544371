import { Link } from "gatsby"
import { css } from "@emotion/core"
import React from "react"

import logo from "../../images/planz-icon.png"
import symbol from "../../images/planz-symbol.svg"
import close from "../../images/close.png"
import { containerWidth } from "../../constants/css.json"

const Header = ({ currentPath, isOpen, toggleModal }) => {
  return (
    <header css={container}>
      <div css={container__body}>
        <Link to="/" style={{ textDecoration: "none" }}>
          <img src={logo} alt="alt" css={logoButtonStyle} />
        </Link>
        {/* <div css={links}>
          <Link css={currentPath === "/" ? currentLink : link} to="/">
            서비스 소개
          </Link>
          <Link
            css={currentPath === "/contact" ? currentLink : link}
            to="/contact"
          >
            문의
          </Link>
        </div> */}
      </div>
    </header>
  )
}

const container = css`
  display: flex;
  @media (max-width: ${containerWidth}) {
    position: fixed;
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 59px;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 3px 6px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  border-bottom: none;
  z-index: 1;
`

const container__body = css`
  @media (max-width: ${containerWidth}) {
    width: 100%;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: ${containerWidth};
`
const links = css`
  display: flex;
  flex-direction: row;
  font-family: "Open Sans";
`

const link = css`
  @media (max-width: ${containerWidth}) {
    margin-left: 20px;
  }
  margin-left: 60px;
  text-decoration: none;
  color: #707070;
  font-weight: lighter;
`

const currentLink = css`
  margin-left: 20px;
  text-decoration: none;
  color: rgb(5, 5, 5);
  font-family: "Open Sans Reg";
`
const logoButtonStyle = css`
  width: 40px;
`

const menuButtonStyle = css`
  @media (max-width: ${containerWidth}) {
    display: block;
    &:focus {
      outline: 0;
    }
  }
  display: none;
  padding: 0 0 0 0;
  border: none;
  background-color: white;
  width: 58px;
  height: 38px;
`

const symbolImgStyle = css`
  width: 38px;
  margin-right: 20px;
`
const closeImgStyle = css`
  width: 18px;
  margin-right: 20px;
`

export default Header
