// external modules
import React from "react"
import { css } from "@emotion/core"

// internal modules
import Summary from "./summary"
import Partners from "./partners"
import Divider from "./divider"
import Terms from "./terms"
import { containerWidth } from "../../../constants/css.json"

type FooterProps = {
  setLangIndex: (index: number) => void
  langIndex: number
}
const Footer: React.FC<FooterProps> = ({ langIndex, setLangIndex }) => (
  <footer css={footerStyle}>
    <Summary setLangIndex={setLangIndex} langIndex={langIndex} />
    <div css={footerDividerStyle}>
      <Divider />
    </div>
    <Partners />
    <Terms langIndex={langIndex} />
  </footer>
)

const footerStyle = css`
  @media (max-width: ${containerWidth}) {
    padding-bottom: 24px;
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(255, 255, 255);
  padding-bottom: 40px;
  font-family: Spoqa Han Sans;
`
const footerDividerStyle = css`
  @media (max-width: ${containerWidth}) {
    display: none;
  }
`

export default Footer
